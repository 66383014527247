import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { storage, auth, db } from '../firebaseConfig';
import { createUserWithEmailAndPassword, sendEmailVerification } from "firebase/auth";
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { doc, setDoc } from "firebase/firestore";
import Footer from "./Footer";
import KiyakuNoHeader from "./KiyakuNoHeader";
import {
    currentYear,
    yearOptions,
    monthOptions,
    dayOptions,
    formatToTwoDigits,
    validateFileSize,
    generateRandomStr
} from './common';

const Register = () => {
    const [password, setPassword] = useState("");
    const [email, setEmail] = useState("");
    const [storeName, setStoreName] = useState("");
    const [sei, setSei] = useState("");
    const [mei, setMei] = useState("");
    const name = sei + mei;
    const [seiKana, setSeiKana] = useState("");
    const [meiKana, setMeiKana] = useState("");
    const kana = seiKana + meiKana;
    const [gender, setGender] = useState('');
    const [birthYear, setBirthYear] = useState(currentYear - 15);
    const [birthMonth, setBirthMonth] = useState("01");
    const [birthDay, setBirthDay] = useState("01");
    const [zipcode, setZipcode] = useState("");
    const [addressOrg, setAddressOrg] = useState("");
    const [address, setAddress] = useState("");
    const [phone, setPhone] = useState("");
    const [job, setJob] = useState("");
    const [idVerifyDocs, setIdVerifyDocs] = useState("");
    const [bankName, setBankName] = useState("");
    const [bankBranchName, setBankBranchName] = useState("");
    const [bankBranchCode, setBankBranchCode] = useState("");
    const [bankAccountName, setBankAccountName] = useState("");
    const [bankAccountNumber, setBankAccountNumber] = useState("");
    const [userType, setUserType] = useState("userTypeInd"); // userTypeInd: 個人, userTypeCorp: 法人
    const [invoiceIssuer, setInvoiceIssuer] = useState("invoiceIssuerNo"); // 適格請求書発行事業者 invoiceIssuerYes: はい, invoiceIssuerNo: いいえ
    const [invoiceIssuerNumber, setInvoiceIssuerNumber] = useState(""); // 適格請求書発行事業者番号
    const [file1, setFile1] = useState(null); // 身分証ファイル
    const [file2, setFile2] = useState(null);
    const [file3, setFile3] = useState(null);
    const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB

    const [isReceivingAddress, setIsReceivingAddress] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error, setError] = useState("");
    const [termsAccepted, setTermsAccepted] = useState(false);
    const navigate = useNavigate();

    const handleChange = async (event) => {
        const inputValue = event.target.value;
        setZipcode(inputValue);

        // 7桁の郵便番号が入力されたらAPIを呼び出す
        if (inputValue.length === 7 && /^\d{7}$/.test(inputValue)) {
            setIsReceivingAddress(true);
            try {
                const response = await fetch(`https://zipcloud.ibsnet.co.jp/api/search?zipcode=${inputValue}`);
                const data = await response.json();

                if (data.status === 200 && data.results) {
                    const adr = data.results[0];
                    setAddress(`${adr['address1']}${adr['address2']}${adr['address3']}`);
                    setAddressOrg(`${adr['address1']}${adr['address2']}${adr['address3']}`);
                } else {
                    setError('該当する郵便番号は見つかりませんでした。');
                    setAddress('');
                    setAddressOrg('');
                }
            } catch (error) {
                setError('エラーが発生しました。もう一度試してください。');
                setAddress('');
                setAddressOrg('');
            } finally {
                setIsReceivingAddress(false);
            }
        } else {
            setAddress('');
            setAddressOrg('');
        }
    };

    const checkRequired = (value, name) => {
        if (!value) {
            setError(name + "を入力してください");
            setIsSubmitting(false);
            return true;
        }
        return false;
    }

    const getUploadedUrl = (uploadedUrls, index) => {
        try {
            let uploadedUrl = uploadedUrls[index];
            if (!uploadedUrl) {
                uploadedUrl = "";
            }
            return uploadedUrl;
        } catch (err) {
            return "";
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        let err_code = "001";

        try {
            if (!termsAccepted) { // 利用規約
                setError("利用規約に同意してください");
                setIsSubmitting(false);
                return;
            }

            if (checkRequired(email, "メールアドレス")) {
                return;
            }
            if (checkRequired(password, "パスワード")) {
                return;
            }
            if (checkRequired(userType, "名義")) {
                return;
            }

            if (userType === "userTypeInd") {
                if (checkRequired(name, "氏名")) {
                    return;
                }
                if (checkRequired(kana, "氏名(カナ)")) {
                    return;
                }
                if (checkRequired(gender, "性別")) {
                    return;
                }
                if (checkRequired(job, "ご職業")) {
                    return;
                }
                if (checkRequired(idVerifyDocs, "身分証")) {
                    return;
                }

            } else if (userType === "userTypeCorp") {
                if (checkRequired(storeName, "法人名")) {
                    return;
                }
                if (checkRequired(name, "担当者名")) {
                    return;
                }
                if (checkRequired(kana, "担当者名(カナ)")) {
                    return;
                }
            }

            if (checkRequired(address, "住所")) {
                return;
            }
            if (checkRequired(phone, "電話番号")) {
                return;
            }
            if (addressOrg === address) { // 住所
                setError("住所の番地が入力されていません");
                setIsSubmitting(false);
                return;
            }
            if (invoiceIssuer === "invoiceIssuerYes" && invoiceIssuerNumber.length < 13) { // インボイス番号が13桁未満
                setError("インボイス番号が正しくありません");
                setIsSubmitting(false);
                return;
            }

            const files = [file1, file2, file3].filter(file => file !== null); // ファイルサイズチェック
            if (files.some(file => !validateFileSize(file, MAX_FILE_SIZE))) {
                setError("登録に失敗しました: ファイルサイズが"+MAX_FILE_SIZE+"MBを超えています");
                setIsSubmitting(false);
                return;
            }

            // ユーザー登録
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;
            err_code = "002";

            // 画像ファイルをアップロード
            const promises = [];
            for (let i = 0; i < files.length; i++) {
                const file = files[i];
                const storageRef = ref(storage, `id_documents/${user.uid}_${generateRandomStr(6)}_${file.name}`);
                const uploadTask = uploadBytes(storageRef, file).then(snapshot => {
                    return getDownloadURL(snapshot.ref);
                });
                promises.push(uploadTask);
            }
            err_code = "003";
            const uploadedUrls = await Promise.all(promises);
            err_code = "004";

            // Firestoreにユーザーデータを保存
            const birthDate = String(birthYear) + formatToTwoDigits(birthMonth) + formatToTwoDigits(birthDay);
            err_code = "005";
            const createdAt = new Date().toISOString();
            err_code = "006";
            const businessType = "0"; // 0:未指定 1:卸グループ加入業者
            const userData = {
                email,
                name,
                kana,
                gender,
                birthDate,
                zipcode,
                address,
                phone,
                job,
                idVerifyDocs,
                createdAt: createdAt,
                updatedAt: createdAt,
                userType,
                invoiceIssuer,
                invoiceIssuerNumber,
                idDocUrl1: getUploadedUrl(uploadedUrls, 0),
                idDocUrl2: getUploadedUrl(uploadedUrls, 1),
                idDocUrl3: getUploadedUrl(uploadedUrls, 2),
                bankName,
                bankBranchName,
                bankBranchCode,
                bankAccountNumber,
                bankAccountName,
                storeName,
                businessType,
            };
            err_code = "007";

            // 個人の場合、法人情報をクリア
            if (userType === "userTypeInd") {
                userData.storeName = "";
            } else { // 法人の場合、個人情報をクリア
                userData.birthDate = "";
                userData.gender = "";
                userData.job = "";
                userData.idVerifyDocs = "";
            }
            err_code = "008";

            await setDoc(doc(db, "users", user.uid), userData);
            err_code = "009";

            // メール確認完了画面に続行ボタンを表示、そのままログインできるようにする
            const actionCodeSettings = {
                url: 'https://shinsoku-tcg.com/'
            };
            await sendEmailVerification(user, actionCodeSettings); // メール確認メールを送信
            err_code = "010";

            navigate("/");

        } catch (err) {
            if (err.message && err.message.indexOf("email-already-in-use") !== -1) {
                setError("["+err_code+"] 登録に失敗しました。恐れ入りますが、このメッセージをスタッフにお見せください。(このメールアドレスは既に使われています)");
                console.log("["+err_code+"] 登録に失敗しました。恐れ入りますが、このメッセージをスタッフにお見せください。(このメールアドレスは既に使われています)");
            } else {
                setError("["+err_code+"] 登録に失敗しました。恐れ入りますが、このメッセージをスタッフにお見せください。(" + (err.message || "不明なエラーが発生しました") + ")");
                console.log("["+err_code+"] 登録に失敗しました。恐れ入りますが、このメッセージをスタッフにお見せください。(" + (err.message || "不明なエラーが発生しました") + ")");
            }
            setIsSubmitting(false);
        }
    };

    const changeUserType = (e) => {
        setUserType(e.target.value);
        if (e.target.value === "userTypeCorp") {
            setInvoiceIssuer("invoiceIssuerYes");
            setInvoiceIssuerNumber("");
        } else {
            setInvoiceIssuer("invoiceIssuerNo");
            setInvoiceIssuerNumber("");
        }
    }

    return (
        <div>
            <form>
                <div className="container mt-5">
                    <h2 style={{textAlign: 'center', marginBottom: '10px'}}>シンソク会員登録</h2>
                    <div style={{overflowY: 'scroll', height: '300px', border: '1px solid #ccc'}}>
                        <KiyakuNoHeader/>
                    </div>
                    <div style={{textAlign: 'center', marginTop: '10px', marginBottom: '10px', fontSize: '24px'}}>
                        <label>
                            <input
                                type="checkbox"
                                checked={termsAccepted}
                                onChange={(e) => setTermsAccepted(e.target.checked)}
                                style={{marginRight: '5px'}}
                                required={true}
                            />
                            利用規約に同意する
                        </label>
                    </div>
                </div>
                <div className="container mt-5">
                    <table className="table table-bordered">
                        <tbody>
                        <tr>
                            <th className="required">メールアドレス</th>
                            <td>
                                <input
                                    type="email"
                                    id="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    placeholder="メールアドレスを入力"
                                    required={true}
                                    style={{width: '100%'}}
                                    autoComplete={"username"}
                                />
                            </td>
                        </tr>
                        <tr>
                            <th className="required">パスワード</th>
                            <td>
                                <input
                                    type="password"
                                    onChange={(e) => setPassword(e.target.value)}
                                    placeholder="パスワードを入力"
                                    required={true}
                                    style={{width: '100%'}}
                                    autoComplete={"new-password"}
                                />
                            </td>
                        </tr>
                        <tr>
                            <th className="w-25 required">名義</th>
                            <td>
                                <input
                                    type="radio"
                                    name="userType"
                                    id="userTypeInd"
                                    value="userTypeInd"
                                    checked={userType === "userTypeInd"}
                                    onChange={(e) => changeUserType(e)}
                                    required={true}
                                />
                                <label htmlFor={"userTypeInd"} style={{marginRight: '10px'}}>個人</label>
                                <input
                                    type="radio"
                                    name="userType"
                                    id="userTypeCorp"
                                    value="userTypeCorp"
                                    checked={userType === "userTypeCorp"}
                                    onChange={(e) => changeUserType(e)}
                                    required={true}
                                />
                                <label htmlFor={"userTypeCorp"}>法人</label>
                            </td>
                        </tr>
                        <tr>
                            <th className="w-25 required">適格請求書発行事業者</th>
                            <td>
                                {userType === "userTypeInd" && (
                                    <>
                                        <input
                                            type="radio"
                                            name="invoiceIssuer"
                                            id="invoiceIssuerNo"
                                            value="invoiceIssuerNo"
                                            checked={invoiceIssuer === "invoiceIssuerNo"}
                                            onChange={(e) => setInvoiceIssuer(e.target.value)}
                                            required={true}
                                        />
                                        <label htmlFor={"invoiceIssuerNo"}>いいえ</label>
                                    </>
                                )}
                                <input
                                    type="radio"
                                    name="invoiceIssuer"
                                    id="invoiceIssuerYes"
                                    value="invoiceIssuerYes"
                                    checked={invoiceIssuer === "invoiceIssuerYes"}
                                    onChange={(e) => setInvoiceIssuer(e.target.value)}
                                    required={true}
                                />
                                <label htmlFor={"invoiceIssuerYes"} style={{marginRight: '10px'}}>はい</label>
                                <div>※申請手続をしていない個人の方は「いいえ」を選択してください</div>
                            </td>
                        </tr>
                        <tr>
                            <th className={invoiceIssuer === "invoiceIssuerYes" ? "w-25 required" : "w-25"}>インボイス番号</th>
                            <td>
                                <input
                                    type="text"
                                    value={invoiceIssuerNumber}
                                    onChange={(e) => setInvoiceIssuerNumber(e.target.value)}
                                    placeholder="例:T1234567890123"
                                    style={{width: '100%'}}
                                    disabled={invoiceIssuer === "invoiceIssuerNo"}
                                    required={invoiceIssuer === "invoiceIssuerYes"}
                                />
                            </td>
                        </tr>
                        {userType === "userTypeInd" && ( // 個人の場合のフォーム
                            <>
                                <tr>
                                    <th className="w-25 required">氏名</th>
                                    <td>
                                        <input
                                            type="text"
                                            value={sei}
                                            onChange={(e) => setSei(e.target.value)}
                                            placeholder="例:山田"
                                            required={true}
                                            style={{width: '50%'}}
                                        />
                                        <input
                                            type="text"
                                            value={mei}
                                            onChange={(e) => setMei(e.target.value)}
                                            placeholder="例:太郎"
                                            required={true}
                                            style={{width: '50%'}}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th className="required">氏名(カナ)</th>
                                    <td>
                                        <input
                                            type="text"
                                            value={seiKana}
                                            onChange={(e) => setSeiKana(e.target.value)}
                                            placeholder="例:ヤマダ"
                                            required={true}
                                            style={{width: '50%'}}
                                        />
                                        <input
                                            type="text"
                                            value={meiKana}
                                            onChange={(e) => setMeiKana(e.target.value)}
                                            placeholder="例:タロウ"
                                            required={true}
                                            style={{width: '50%'}}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th className="required">性別</th>
                                    <td>
                                        <select value={gender} onChange={(e) => setGender(e.target.value)}
                                                required={true}>
                                            <option value="" disabled>選択してください</option>
                                            <option value="f">女性</option>
                                            <option value="m">男性</option>
                                            <option value="o">その他</option>
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <th className="required">生年月日</th>
                                    <td>
                                        <div className="row">
                                            <div className="col-2 date_select_box">
                                                <select value={birthYear} onChange={(e) => setBirthYear(e.target.value)}
                                                        required={true}>
                                                    {yearOptions.map(year => (
                                                        <option key={year} value={year}>{year}年</option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="col-2 date_select_box">
                                                <select value={birthMonth}
                                                        onChange={(e) => setBirthMonth(e.target.value)} required={true}>
                                                    {monthOptions.map(month => (
                                                        <option key={month} value={month}>{month}月</option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="col-2 date_select_box">
                                                <select value={birthDay} onChange={(e) => setBirthDay(e.target.value)}
                                                        required={true}>
                                                    {dayOptions.map(day => (
                                                        <option key={day} value={day}>{day}日</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th className="required">ご職業</th>
                                    <td>
                                        <select
                                            value={job}
                                            onChange={(e) => setJob(e.target.value)}
                                            required={true}
                                        >
                                            <option value="" disabled>選択してください</option>
                                            <option value="company_employee">会社員</option>
                                            <option value="contract_employee">契約社員・派遣社員</option>
                                            <option value="part_time">パート・アルバイト</option>
                                            <option value="public_servant">公務員</option>
                                            <option value="self_employed">自営業</option>
                                            <option value="homemaker">専業主婦・主夫</option>
                                            <option value="student">学生</option>
                                            <option value="unemployed">無職</option>
                                            <option value="other">その他</option>
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <th className="required">身分証</th>
                                    <td>
                                        <select
                                            value={idVerifyDocs}
                                            onChange={(e) => setIdVerifyDocs(e.target.value)}
                                            required={true}
                                        >
                                            <option value="" disabled>選択してください</option>
                                            <option value="driver">運転免許証</option>
                                            <option value="health">健康保険証</option>
                                            <option value="student">学生証(顔写真があるもの)</option>
                                            <option value="passport">パスポート</option>
                                            <option value="special_residence">特別永住者証明書</option>
                                            <option value="my_number">マイナンバーカード</option>
                                            <option value="juuki">住民基本カード</option>
                                            <option value="driver2">運転経歴証明書</option>
                                            <option value="other">その他</option>
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <th>身分証画像1</th>
                                    <td>
                                        <input type="file" onChange={(e) => setFile1(e.target.files[0])}
                                               accept=".png, .jpeg, .jpg, .gif"/>
                                        <div style={{color: "red"}}>※店頭買取の場合はアップロード不要です</div>
                                        <div>※裏面もある場合は裏面の画像もアップロードしてください</div>
                                    </td>
                                </tr>
                                <tr>
                                    <th>身分証画像2</th>
                                    <td>
                                        <input type="file" onChange={(e) => setFile2(e.target.files[0])}
                                               accept=".png, .jpeg, .jpg, .gif"/>
                                    </td>
                                </tr>
                                <tr>
                                    <th>身分証画像3</th>
                                    <td>
                                        <input type="file" onChange={(e) => setFile3(e.target.files[0])}
                                               accept=".png, .jpeg, .jpg, .gif"/>
                                    </td>
                                </tr>
                            </>
                        )}
                        {userType === "userTypeCorp" && ( // 法人の場合のフォーム
                            <>
                                <tr>
                                    <th className="w-25 required">法人名</th>
                                    <td>
                                        <input
                                            type="text"
                                            value={storeName}
                                            onChange={(e) => setStoreName(e.target.value)}
                                            placeholder="例:○○株式会社"
                                            required={true}
                                            style={{width: '100%'}}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th className="w-25 required">担当者名</th>
                                    <td>
                                        <input
                                            type="text"
                                            value={sei}
                                            onChange={(e) => setSei(e.target.value)}
                                            placeholder="例:山田"
                                            required={true}
                                            style={{width: '50%'}}
                                        />
                                        <input
                                            type="text"
                                            value={mei}
                                            onChange={(e) => setMei(e.target.value)}
                                            placeholder="例:太郎"
                                            required={true}
                                            style={{width: '50%'}}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th className="required">担当者名(カナ)</th>
                                    <td>
                                        <input
                                            type="text"
                                            value={seiKana}
                                            onChange={(e) => setSeiKana(e.target.value)}
                                            placeholder="例:ヤマダ"
                                            required={true}
                                            style={{width: '50%'}}
                                        />
                                        <input
                                            type="text"
                                            value={meiKana}
                                            onChange={(e) => setMeiKana(e.target.value)}
                                            placeholder="例:タロウ"
                                            required={true}
                                            style={{width: '50%'}}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th colSpan="2">登記簿謄本、担当者様の名刺の画像</th>
                                </tr>
                                <tr>
                                    <th>画像1</th>
                                    <td>
                                        <input type="file" onChange={(e) => setFile1(e.target.files[0])}
                                               accept=".png, .jpeg, .jpg, .gif"/>
                                        <div>※裏面もある場合は裏面の画像もアップロード</div>
                                    </td>
                                </tr>
                                <tr>
                                    <th>画像2</th>
                                    <td>
                                        <input type="file" onChange={(e) => setFile2(e.target.files[0])}
                                               accept=".png, .jpeg, .jpg, .gif"/>
                                    </td>
                                </tr>
                                <tr>
                                    <th>画像3</th>
                                    <td>
                                        <input type="file" onChange={(e) => setFile3(e.target.files[0])}
                                               accept=".png, .jpeg, .jpg, .gif"/>
                                    </td>
                                </tr>
                            </>
                        )}
                        <tr>
                            <th className="required">住所</th>
                            <td>
                                <input
                                    type="text"
                                    pattern={"[0-9]+"}
                                    value={zipcode}
                                    onChange={handleChange}
                                    placeholder="郵便番号を入力"
                                    maxLength={7}
                                    required={true}
                                />※ハイフンなし
                                <br></br>
                                <input
                                    type="text"
                                    value={address}
                                    onChange={(e) => setAddress(e.target.value)}
                                    placeholder="住所を入力"
                                    maxLength={200}
                                    style={{width: '100%', marginTop: '10px'}}
                                    required={true}
                                    disabled={isReceivingAddress}
                                />
                            </td>
                        </tr>
                        <tr>
                            <th className="required">電話番号</th>
                            <td>
                                <input
                                    type="text"
                                    pattern={"[0-9]+"}
                                    value={phone}
                                    onChange={(e) => setPhone(e.target.value)}
                                    placeholder="電話番号を入力"
                                    maxLength={15}
                                    required={true}
                                />※ハイフンなし
                            </td>
                        </tr>
                        <tr>
                            <th colSpan="2">振込先情報 ※現金払いをご希望の場合は入力不要です
                                必ずご本人名義の口座を登録してください
                            </th>
                        </tr>
                        <tr>
                            <th>銀行名</th>
                            <td>
                                <input
                                    type="text"
                                    value={bankName}
                                    onChange={(e) => setBankName(e.target.value)}
                                    placeholder="例:○○銀行"
                                    style={{width: '100%'}}
                                />
                            </td>
                        </tr>
                        <tr>
                            <th>支店名</th>
                            <td>
                                <input
                                    type="text"
                                    value={bankBranchName}
                                    onChange={(e) => setBankBranchName(e.target.value)}
                                    placeholder="例:○○支店"
                                    style={{width: '100%'}}
                                />
                            </td>
                        </tr>
                        <tr>
                            <th>支店コード</th>
                            <td>
                                <input
                                    type="text"
                                    value={bankBranchCode}
                                    onChange={(e) => setBankBranchCode(e.target.value)}
                                    placeholder="例:001"
                                    style={{width: '100%'}}
                                />
                            </td>
                        </tr>
                        <tr>
                            <th>口座番号</th>
                            <td>
                                <input
                                    type="text"
                                    value={bankAccountNumber}
                                    onChange={(e) => setBankAccountNumber(e.target.value)}
                                    placeholder="例:1234567"
                                    style={{width: '100%'}}
                                />
                            </td>
                        </tr>
                        <tr>
                            <th>口座名義(カタカナ)</th>
                            <td>
                                <input
                                    type="text"
                                    value={bankAccountName}
                                    onChange={(e) => setBankAccountName(e.target.value)}
                                    placeholder="例:ヤマダタロウ"
                                    style={{width: '100%'}}
                                />
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div style={{textAlign: 'center'}}>
                    {error && <p style={{color: "red", fontWeight: "bold"}}>{error}</p>}
                    <br/>
                    <button
                        className="btn btn-primary"
                        onClick={handleSubmit}
                        disabled={isSubmitting}
                    >仮登録
                    </button>
                </div>
            </form>
            <Footer/>
        </div>
    );
};

export default Register;
